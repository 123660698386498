/* Make the entire container take up the full screen */
.map-fullscreen-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Map container to fill the screen */
.map-container {
  width: 100%;
  height: 100%;
}

/* Filters overlay - top left */
.filters-overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

/* Search overlay - top right */
.search-overlay {
  position: absolute;
  width: 20%;
  top: 10px;
  right: 20px;
  border-radius: 8px;
  z-index: 10;
  background-color: white;
}

/* Refresh button overlay - bottom center */
.refresh-button-overlay {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
/* Filters container */
/* Map controls adjustments */
.azure-maps-control-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1000 !important;
}

.azure-maps-control-container .azure-maps-control button {
  width: 40px !important;
  height: 40px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.azure-maps-control-container .azure-maps-control {
  margin-bottom: -4px !important; /* Adjust the value as needed */
}

.azure-maps-control-container .azure-maps-control button:hover {
  background-color: rgba(255, 255, 255, 1) !important;
}

.azure-maps-control-container .azure-maps-control button svg {
  width: 24px !important;
  height: 24px !important;
}

/* Ensure other overlays don't interfere */
.filters-overlay,
.search-overlay,
.refresh-button-overlay {
  z-index: 10;
  display: flex;
}
.filters-overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  gap: 10px; /* Optional spacing between rows */
}

.filters-row {
  display: flex;
  flex-direction: row; /* Place filters side-by-side */
  gap: 10px; /* Optional spacing between the two filters divs */
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
/* Map controls should be above overlays */
.azure-maps-control-container {
  z-index: 100;
}



.filter-button {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-family: "Lato", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
  border: 1px transparent !important;
  border-radius: 20px !important;
  padding: 8px 16px !important;
  margin-right: 10px !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4) !important; /* Light shadow */
}

.filter-button:hover {
  background-color: #ffffff !important;
}

/* Icon styling */
.filter-button .icon {
  width: 20px; /* Reduced from 20px to 16px */
  height: 20px; /* Reduced from 20px to 16px */
  margin-right: 2px;
  object-fit: contain;
}

/* Active state for all filters */
.filter-button.active {
  border-radius: 30px !important;
  border: 1px solid #2196f3 !important; /* Blue border */
  background: #e3f2fd !important; /* Light blue background */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25) !important; /* Deeper shadow */
}

/* Icon colors based on filter type when active */
.filter-button.active.green-filter .icon {
  color: green !important;
}

.filter-button.active.yellow-filter .icon {
  color: gold !important;
}

.filter-button.active.red-filter .icon {
  color: red !important;
}

.filter-button.active.gated-filter .icon {
  color: black !important;
}
