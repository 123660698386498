.aval-custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.aval-custom-modal {
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  width: 500px; /* Made narrower */
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 2vh;
}

.aval-custom-modal-header {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
}

.aval-custom-modal-title-wrapper {
  display: flex;
  align-items: center;
}

.aval-custom-clock-icon {
  width: 66px;
  height: 66px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: 3px;
}

.aval-custom-modal-title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--Colors-Text-color, #333);
  margin: 0;
}

.aval-custom-modal-subtitle {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--Colors-Text-color, #333);
  margin: 5px 0 0 0;
}

.aval-custom-close-button {
  position: absolute;
  top: -10px;
  right: -15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.aval-custom-modal-body {
  padding-top: 20px;
}

.aval-custom-datetime-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.aval-custom-calendar-icon-wrapper {
  margin-right: 15px; /* Space between the calendar icon and input field */
}

.aval-custom-calendar-icon {
  width: 22px;
  height: 22px;
}

.aval-custom-datetime-icon {
  width: 22px;
  height: 30px;
  margin-left: 0px;
  margin-right: 15px;
  position: relative;
}

.aval-custom-time-group {
  display: flex;
  align-items: center;
}

.aval-custom-input-container {
  display: inline-flex;
  align-items: center;
  background: #f0f0f0; /* Keeps the background for the container */
  padding: 0px 0px;
  border-radius: 5px;
  width: auto;
  margin-right: 10px; /* Adds some space between the input fields */
}

.aval-custom-input-container-small {
  padding: 4px 10px; /* Smaller padding for smaller container */
}

.aval-custom-input {
  border: none;
  background: none; /* Input itself has no background */
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  width: 100%;
  text-align: left;
}

.aval-custom-arrow-container {
  padding: 0 10px;
}

.aval-custom-arrow-icon {
  width: 20px;
}

.aval-custom-duration-text {
  color: #4a4949;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-left: 1px;
}

.aval-custom-recurrence-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0; /* No gap between flex items */
  align-items: flex-start; /* Align items to the start */
  margin-bottom: 20px;
}

.aval-custom-recurrence-option {
  width: 50%; /* Ensures two items per row */
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust vertical spacing */
}

.aval-custom-recurrence-icon-wrapper {
  display: flex;
  align-items: center;
  margin-left: 2px; /* Adjust to align under the icons */
  margin-bottom: 20px; /* Space between the icon and options */
}

.aval-custom-recurrence-icon {
  width: 18px;
  height: 24px;
}

.aval-custom-recurrence-option input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
}

.aval-custom-recurrence-option input[type="radio"]:checked {
}

/* .aval-custom-recurrence-option input[type="radio"]:checked::before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('./assets/Radio button.svg');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
} */

.aval-custom-recurrence-option label {
  margin-left: 8px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.aval-custom-weekdays-group {
  margin-top: 15px;
}

.aval-custom-weekdays-title {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-bottom: 15px;
}

.aval-custom-weekdays-options {
  display: flex;
  align-items: center !important; /* Aligns items vertically center */
  gap: 10px; /* Space between the label and buttons */

  margin-bottom: -10px;
}

.aval-custom-weekday-button {
  padding: 8px 10px;
  width: 60px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  border-radius: 5px;
  border: 1px solid #dad7d7;
  cursor: pointer;
  background-color: white;
  color: #333;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}

.aval-custom-weekday-button.selected {
  background-color: #00a5e5;
  border-color: rgb(255, 255, 255);
  color: white;
}

.aval-custom-weekday-button:disabled {
  background-color: transparent;
  border-color: #ddd;
  color: #999;
}

.aval-custom-weekdays-options.disabled .aval-custom-weekday-button {
  pointer-events: none;
  background-color: white;
  border-color: #d3d3d3;
  color: #999;
}

.aval-custom-recurrence-custom {
  margin-top: 20px;
}

.aval-custom-recurrence-custom-every {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  font-size: small;
  color: #000;
}

.aval-custom-recurrence-input {
  padding: 8px;
  border-radius: 4px;
  border: 0px solid #d7d3d3;
  background: #f0f0f0; /* Added background styling */
  font-size: 14px; /* Ensure font size is consistent */
  width: 50px;
}

.aval-custom-recurrence-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d7d3d3;
  font-size: 14px;
}

.aval-custom-recurrence-end {
  margin-top: 15px;
}

.aval-custom-recurrence-end-title {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px;
}

.aval-custom-recurrence-end-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.aval-custom-recurrence-end-option {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px; /* To align with spacing used in other inputs */
}

#endAfterCount {
  width: fit-content;
}

.aval-custom-recurrence-end-option input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
}

.aval-custom-recurrence-end-option label {
  margin-left: 8px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.aval-custom-recurrence-end-input {
  padding: 5px; /* Adjust padding for desired input height */
  border-radius: 4px;
  border: 0px solid #d7d3d3;
  width: 50px; /* Adjust width to make the field narrower */
  font-size: 14px; /* Adjust font size if needed */
}

.aval-custom-recurrence-end-date {
  padding: 8px;
  border: 1px solid #d7d3d3;
  font-size: 14px;
  background: #f0f0f0; /* Consistent background styling */
}

.aval-custom-recurrence-holiday {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -210px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start; /* Aligns content to the left */
  width: 100%; /* Ensure it takes the full width of the modal */
  text-align: left; /* Aligns the text to the left */
}
.aval-custom-recurrence-holiday label {
  flex-shrink: 0; /* Prevents the label from shrinking */
  margin-left: -210px;
}

.aval-custom-recurrence-radio {
  appearance: none;
  width: 10px !important;
  height: 10px !important;
  border: 2px solid #d7d3d3;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aval-custom-recurrence-radio:checked {
  border-color: transparent;
}

.aval-custom-recurrence-radio:checked::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("./assets/Radio button.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.aval-custom-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.aval-custom-button-primary {
  background-color: #4899dc;
  color: white;
  margin-right: 10px;
}

.aval-custom-button-primary:hover {
  background-color: #4899dc;
}

.aval-custom-button-delete {
  margin-right: 10px;

}
