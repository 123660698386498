/* Fleet Efficiency Section */
.fleet-efficiency {
  width: 100%;
  padding: 60px 0;
  background: var(--BG-Dark, radial-gradient(194.79% 123.02% at 93.75% 92.19%, #102636 0%, #12090D 41.43%));
}

.fleet-efficiency .container {
  width: 100%;
  max-width: 1200px; /* Use a max-width that works for your design */
  margin: 0 auto;
  padding: 0 20px;
}

.fleet-efficiency .content {
  text-align: left;
}

.fleet-efficiency h1 {
  color: var(--White, #FFF);
  font-family: 'Inter', sans-serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 70px; /* 116.667% */
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}

.fleet-efficiency .underline {
  border: none;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  max-width: 1027px; /* Adjust the maximum width */
  margin: 0 auto 40px;
}

.fleet-efficiency .text-image-row {
  display: flex;
  flex-direction: column; /* Default to column layout */
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .fleet-efficiency .text-image-row {
    flex-direction: row; /* Row layout for larger screens */
    align-items: flex-start;
  }
}

.fleet-efficiency .image-container {
  width: 100%;
  max-width: 475px;
  height: 500px;
  margin-bottom: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .fleet-efficiency .image-container {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.fleet-efficiency .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

.fleet-efficiency .text-container {
  flex: 1;
  max-width: 500px;
  color: var(--White, #FFF);
  text-align: left;
  margin-left: 0;
}

@media (min-width: 1100px) {
  .fleet-efficiency .text-container {
    margin-left: 32px;
  }
}

.fleet-efficiency .text-container h2 {
  color: var(--White, #FFF);
  font-family: 'Inter', sans-serif;
  font-size: 35px;
  font-weight: 500;
  line-height: 50px; /* 125% */
  letter-spacing: 0.3px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.fleet-efficiency .text-container p {
  color: var(--White, #FFF);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}

.fleet-efficiency .text-container ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.fleet-efficiency .text-container ul li {
  color: var(--White, #FFF);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.fleet-efficiency .text-container ul li::before {
  content: '•';
  color: var(--White, #FFF);
  position: absolute;
  left: 0;
  top: 0;
}

.fleet-efficiency .learn-more {
  color: var(--Link, #ffffff);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.5px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.learn-more-arrow {
  width: 19.605px;
  height: 29.269px;
  flex-shrink: 0;
  margin-left: 0.2rem;
}
