.OwnerRegistration-Container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.section {
  margin-bottom: 20px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.form-field {
  flex: 1;
  margin-right: 10px;
  position: relative;
  /* Add this to ensure the search results are positioned relative to the input field */
}

.form-field input {
  width: 100%;
  padding: 14px;
  margin-top: 5px;
}

.validation-error-owner {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 100%;
  /* Position it right below the input field */
  left: 0;
  transform: translateY(5px);
  /* Add a slight gap */
}

.submit-button {
  background-color: #0A66C2;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 5%;
}

.submit-button:hover {
  background-color: #0A66C2;
}

.mandatorymark {
  color: red;
}


.search-results-owner {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -25px;
}

.input-address {
  position: relative;
  /* width: 100%; */
  margin-bottom: 0px !important;
}

.search-results-owner li {
  padding: 10px;
  cursor: pointer;
}

.search-results-owner li:hover {
  background-color: #f0f0f0;
}

.add-property-btn {
  background-color: #0A66C2 !important;
  padding: 14px 30px !important;
  color: white !important;
  text-align: left !important;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  /* Aligns buttons to the right */
  margin-top: 20px;
}

.buttongroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.submit-button,
.cancel-button {
  width: 10%;
  /* Ensure both buttons take up the same width */
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.submit-button {
  background-color: #0A66C2;
  color: white;
}

.submit-button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 10%;
  margin-right: 10px
}

.submit-button.enabled {
  background-color: #0A66C2;
  color: white;
}

.submit-button.disabled {
  background-color: lightgrey;
  color: black;
}


.cancel-button {
  background-color: lightgray;
  color: black;
  margin-right: 10px;
}


.alignEnd {
  text-align: end !important
}

.alignLeft {
  text-align: left !important
}

.remembercheckbox {
  padding: 42px 12px 12px 125px;
}

.country {
  width: 120%;
  padding: 13px;
  margin-top: 5px;
}

/* PropertyCard.css */
.property-card {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
}

.property-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.owner-details {
  margin-right: 16px;
}

.owner-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.property-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  flex: 1;
  /* Allow blocks to take equal space */
}

.info-header {
  font-size: 12px;
  color: #666;
  margin: 0;
}

.info-data {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-top: 4px;
  position: relative;
  /* Positioning for tooltip */
  display: inline-block;
  /* Keep inline for truncation */
  overflow: hidden;
  /* Prevent overflow */
  white-space: nowrap;
  /* Prevent text wrapping */
  text-overflow: ellipsis;
  /* Add ellipsis for overflow text */
  max-width: 100%;
  /* Allow it to take the full remaining space */
}

.info-data:hover::after {
  content: attr(title);
  /* Use the title attribute as the tooltip content */
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: normal;
  /* Allow text to wrap */
  z-index: 1;
  left: 0;
  top: 100%;
  /* Position below the text */
  width: max-content;
  /* Adjust width to fit content */
  max-width: 200px;
  /* Optional: Set a max width for the tooltip */
}

.property-actions {
  display: flex;
  justify-content: flex-end;
}

.action-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.no-property-container {
  text-align: center;
  margin-top: 175px;
}

.building-image {
  width: 525px;
  height: auto;
}

.no-property-text {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.register-link {
  color: #1a73e8;
  text-decoration: none;
}

.register-link:hover {
  text-decoration: underline;
}

.Actions {
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  gap: '0px'
}

.ButtonAction {
  width: "60px" !important;
  height: "30px" !important;
  padding: "2px 2px" !important;
  cursor: "pointer" !important;
  justify-content: "center" !important;
  margin: "12px 12px 12px 12px" !important;

}

.Editbtn {
  background-color: "#0A66C2" !important;
  color: "white" !important;
}

.table-container {
  width: 100%;
  /* Use 100% to take full width of the parent */
  max-width: 80%;
  /* Set a maximum width for the container */
  margin: 0 auto;
  /* Center the container itself */
  overflow-x: auto;
  /* Allow horizontal scrolling if needed */
  justify-content: center;
  padding: 0;
  /* Remove any padding */
  margin-top: -50px;
}

.property-card {
  position: relative;
  /* Ensure overflow is visible so dropdown is not cut off */
  overflow: visible;
}

.dots-menu {
  position: relative;
  z-index: 1;
  /* Ensure the dots icon is above other elements */
}

.dropdown-menu1 {
  position: absolute;
  top: 70px;
  width: min-content;
  /* Adjust based on your layout */
  right: 20px;
  /* Align to the right of the dots menu */
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  z-index: 1000;
  /* Ensure it appears on top */
  display: block;
  /* Ensure it's always visible for testing */
}


.dropdown-option {
  padding: 10px 16px;
  /* Ensure consistent padding */
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: left;
  /* Align text to the left */
  width: 150px;
  /* Set a fixed width if necessary for uniformity */
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.formInputs {
  margin-right: 115px;
  position: relative;
}

.goBackbtn {
  width: 20%;
  margin-top: 30px !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: black;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh !important;
  min-width: 100vw !important;
}

.dialog-body {
  max-height: 100vh;
  max-width: 900px;
  overflow-y: 'auto';
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 75px !important
}

.textcolor {
  color: #0A66C2 !important
}

.otp-inputbox {
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;
  font-size: 24px !important;
  margin-right: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important
}

.verify-otpbtn {
  background-color: #0A66C2 !important;
  color: #fff !important
}

.resend-btn {
  color: #0A66C2 !important;
  text-decoration: underline !important
}

.emailphoneverify-button {
  position: absolute;
  transform: translateY(-50%);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0A66C2;
  color: white;
}

.property-card:hover {
  background-color: #f2f4f4;
  cursor: pointer;
}