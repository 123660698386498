.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #000000;
  position: fixed;
  width: 100%;
  height: 72px;
  z-index: 1000;
}

.header .logo img {
  height: 50px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
  align-items: center; /* Center the nav links vertically */
}

.nav-links ul {
  display: flex;
  gap: 2rem;
  margin: 0;
  list-style: none;
  align-items: center; /* Center the list items vertically */
}

.nav-links li {
  text-transform: uppercase;
}

.nav-links li a {
  cursor: pointer;
  color: white !important;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center; /* Ensure the text inside the links is vertically centered */
}

.auth-buttons {
  display: flex;
  gap: 1rem;
  align-items: center; /* Center the buttons vertically */
}

.auth-buttons a {
  text-decoration: none;
}

.sign-up {
  background: transparent;
  color: #6acd54; /* Green color same as logo */
  border: 2px solid #6acd54;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}

.login-1 {
  background: #6acd54;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.sign-up:hover,
.login-1:hover {
  opacity: 0.8;
}

.menu-toggle {
  display: none;
  position: relative;
  width: 24px; /* Adjusted width to make the icon smaller */
  height: 24px; /* Adjusted height to make the icon smaller */
  cursor: pointer;
  z-index: 1001;
}

.menu-toggle span {
  display: block;
  position: absolute;
  height: 3px; /* Adjusted height to make the lines thinner */
  width: 100%;
  background: white;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.menu-toggle span:nth-child(1) {
  top: 0px;
}

.menu-toggle span:nth-child(2),
.menu-toggle span:nth-child(3) {
  top: 9px; /* Adjusted the top value to reduce space between lines */
}

.menu-toggle span:nth-child(4) {
  top: 18px; /* Adjusted the top value to reduce space between lines */
}

.menu-toggle.open span:nth-child(1) {
  top: 9px; /* Adjusted the top value to match the reduced space */
  width: 0%;
  left: 50%;
}

.menu-toggle.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-toggle.open span:nth-child(4) {
  top: 9px; /* Adjusted the top value to match the reduced space */
  width: 0%;
  left: 50%;
}

@media (max-width: 860px) { /* Adjusted media query to 860px */
  .nav-links {
    position: fixed;
    top: 0;
    right: -100%; /* Changed from left to right */
    width: 100%; /* Adjusted to take full width */
    height: 100%; /* Adjusted to take full height */
    background: #000000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .nav-links.sidebar {
    right: 0; /* Changed from left to right */
  }

  .nav-links ul {
    flex-direction: column;
    gap: 1.5rem;
    height: auto; /* Ensure ul takes only the necessary height */
    display: flex;
    align-items: center; /* Center the links horizontally */
    margin-right: 29px;
  }

  .auth-buttons {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem; /* Adjusted margin to bring the buttons closer to the links */
    display: flex;
    align-items: center; /* Center the buttons horizontally */
  }

  .auth-buttons .sign-up, .auth-buttons .login-1 {
    width: 120px; /* Set a fixed width to match the main page button */
    height: 40px; /* Set a fixed height to match the main page button */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-toggle {
    display: block;
  }
 
}
.logo-container {
  display: flex; /* Aligns items in a row */
  align-items: center; /* Vertically aligns text and logo */
}
.restricted-badge-home {
  background-color: red;
  color: white;
  border-radius: 7px;
  padding: 4px 9px;
  font-size: 1.1rem;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 300px; 
  z-index: 100;
  white-space: nowrap; 
}

@media (max-width: 768px) {
  .restricted-badge-home {
    font-size: 0.9rem; 
    padding: 3px 8px; 
  }
}

@media (max-width: 480px) {
  .restricted-badge-home {
    font-size: 0.8rem; 
    padding: 2px 6px; 
  }
}