:root {
    --BG-Dark: radial-gradient(194.79% 123.02% at 93.75% 92.19%, #102636 0%, #12090D 41.43%);
  }
  
  .background{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: black !important;
    background-size: cover;
  }
  
  .terms-of-service {
    font-family: 'Arial', sans-serif; /* Match font style with footer */
    padding: 2rem;
    background: transparent;
    color: rgba(255, 255, 255, 0.9); /* Set text opacity to 0.9 */
    min-height: 100vh; /* Ensure it covers the full viewport height */
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    max-width: 800px; /* Control the width of the content */
    margin: 0 auto;
  }
  
  .terms-of-service h1 {
    font-size: 2.5rem; /* Keep font size */
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .terms-of-service h2 {
    text-align: left;
    font-size: 1.75rem; /* Keep font size */
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .terms-of-service h3 {
    text-align: left;
    font-size: 1.3rem; /* Keep font size */
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .terms-of-service p, .terms-of-service ul {
    text-align: left;
    font-size: 1rem; /* Keep font size */
    line-height: 1.5; /* Keep line height */
    margin-bottom: 1rem;
  }
  
  .terms-of-service a {
    color: #6acd54; /* Match link color with text opacity */
  }
  
  .terms-of-service a:hover {
    text-decoration: underline;
  }
  
  .terms-of-service ul {
    padding-left: 1.5rem;
  }
  
  .terms-of-service ul li {
    list-style-type: disc;
    margin-bottom: 0.5rem;
  }
  