/* Container for the Who Are We section */
.who-are-we {
  padding: 4rem 2rem; /* Adds padding around the section */
  background-color: #e1f4e4; /* Background color matching the screenshot */
  color: #102636; /* Default text color */
}

/* Container for the content */
.who-are-we .container {
  max-width: 1200px; /* Limits the width of the content */
  margin: 0 auto; /* Centers the container */
  display: flex; /* Flexbox for layout */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  align-items: center; /* Aligns items vertically center */
  flex-direction: row; /* Default flex direction for larger screens */
}

/* Content section */
.who-are-we .content {
  flex: 1; /* Takes up equal space */
  min-width: 300px; /* Minimum width to maintain layout */
  margin-right: 2rem; /* Adds space to the right of the content */
  text-align: left; /* Align text to the left */
}

/* Image section */
.who-are-we .image {
  flex: 1; /* Takes up equal space */
  min-width: 300px; /* Minimum width to maintain layout */
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center image horizontally */
}

/* Image size and styling */
.who-are-we .image img {
  width: 400px; /* Fixed width for the square image */
  height: 400px; /* Fixed height for the square image */
  object-fit: cover; /* Ensures the image covers the square area */
  display: block; /* Removes bottom space caused by inline-block */
}

/* Heading */
.who-are-we h2 {
  font-family: "Inter", sans-serif;
  font-size: 40px; /* Font size for the heading */
  font-weight: 600; /* Font weight for the heading */
  line-height: 50px; /* Line height for the heading */
  letter-spacing: 0.3px; /* Letter spacing for the heading */
  color: var(--Heading-Color, #102636); /* Text color */
  margin-bottom: 1.5rem; /* Adds space below the heading */
}

/* Paragraph text */
.who-are-we p {
  font-family: "Inter", sans-serif;
  font-size: 14px; /* Font size for the paragraph */
  font-weight: 400; /* Font weight for the paragraph */
  line-height: 24px; /* Line height for the paragraph */
  letter-spacing: 0.3px; /* Letter spacing for the paragraph */
  color: var(--Heading-Color, #102636); /* Text color */
  margin-bottom: 2rem; /* Adds space below the paragraph */
}

/* Call to Action button */
.who-are-we .cta-button {
  background-color: #6acd54;
  color: black;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  font-family: "Inter", sans-serif;
  border: none;
  border-radius: 4px;
  cursor: pointer; /* Smooth transition for hover effect */
  font-weight: bold;
}

.who-are-we .cta-button:hover {
  background-color: var(--Link, #28a745); /* Background color on hover */
  color: #fff; /* Text color on hover */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .who-are-we .container {
    flex-direction: column; /* Change flex direction to column for mobile view */
    text-align: center; /* Center text for mobile view */
  }

  .who-are-we .content {
    order: 2; /* Change order to 2 to place it below the image */
    margin-right: 0; /* Remove right margin for mobile view */
    margin-bottom: 2rem; /* Add bottom margin to separate from image */
  }

  .who-are-we .image {
    order: 1; /* Change order to 1 to place it above the content */
  }

  .who-are-we .image img {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    padding-bottom: 15px;
  }
}