/* Main container */
.filter-screen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f8f9fa; /* Slightly lighter background */
}

/* Layout structure */
.filter-layout {
  display: flex;
  flex: 1;
  gap: 20px;
  padding: 24px;
  box-sizing: border-box;
}

/* Left Filters Section */
.left-filters {
  flex: 0 0 300px; /* Slightly wider fixed width */
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-title {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 18px; /* Larger font for main title */
  color: #333;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter-section-title {
  margin: 16px 0 8px;
  font-weight: 700;
  color: #555; /* Darker color for section titles */
  font-size: 14px !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-control {
  margin-bottom: 16px;
}

.apply-button {
  margin-top: 24px;
  background-color: #1976d2 !important;
  color: #fff !important;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px 0;
  text-transform: uppercase;
}

/* Checkbox styling for better spacing */
.filter-group .MuiFormControlLabel-root {
  margin: 4px 0;
  font-size: 14px;
  color: #444;
}

/* Right Tree Section */
.right-tree {
  flex: 1; /* Takes up remaining space */
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  overflow-y: auto;
  font-size: 14px;
}

/* Tree structure customization */
.custom-tree .rc-tree-treenode {
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.8;
  color: #333;
}

.custom-tree .rc-tree-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-tree .sales-count {
  background-color: #e9ecef;
  color: #555;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 4px;
  margin-left: 8px;
}

/* Adjust checkbox alignment */
.filter-group .MuiCheckbox-root {
  padding: 4px;
}

/* Custom Tree Container */
.custom-tree-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}

/* Tree Node */
.custom-tree-node {
  margin-left: 16px;
}

/* Row coloring based on row-even/row-odd classes */
.row-even .tree-node-header {
  background-color: #FFFFFF;
}

.row-odd .tree-node-header {
  background-color: #F5F5F5;
}

/* Node Header */
.tree-node-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  position: relative;
  padding-left: 24px; /* Extra spacing for the connecting line */
}

.vertical-line {
  position: absolute !important;
  width: 1px !important;
  background: #572323;
  z-index: 1000 !important; /* Ensure the line is on top of background elements */
}

/* Hover effect for node header */
.tree-node-header:hover {
  background-color: #f9f9f9;
}

/* Toggle icon spacing */
.tree-toggle-icon {
  font-size: 12px;
  margin-right: 4px;
  color: #666;
}

/* Node title and sales style */
.tree-node-title {
  font-weight: 500;
  color: #333;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.tree-node-sales {
  background-color: #ececec;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
}

/* Children container (vertical line and indent) */
.tree-node-children {
  margin-left: 24px;
  border-left: 1px solid #e0e0e0;
  padding-left: 8px;
  position: relative;
}

/* More pronounced vertical line for children */
.tree-node-children::before {
  content: "";
  position: absolute;
  left: -1px; 
  top: 0;
  bottom: 0;
  width: 1px;
  background: #e0e0e0;
}



/* Adjust icons and spacing */
.icon {
  margin-right: 8px;
}
.tree-node-header.selected {
  background-color: #cce5ff !important; /* highlight color */
  border-left: 4px solid #007bff;
}


