.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 10000;
}

.navbar-logo img {
  height: 40px; /* Adjust as necessary */
}
.navbar-links {
  flex: 1; /* Allow the links to take up available space */
  display: flex;
  justify-content: center; /* Center the links */
  align-items: center;
}
.navbar-links a {
  margin: 11px 25px;
  text-decoration: none;
  padding-left: 2px;
  color: #000000;
  font-weight: 600;
  font-size: larger;
  position: relative;
  padding-bottom: 5px; /* Adjust to match the space between text and underline */
  transition: color 0.3s, border-bottom 0.3s;
  left: -50px;
  right: -50px;
}

.navbar-links a.selected {
  color: #007bff;
  border-bottom: 3px solid #007bff; /* Thicker underline */
}

.navbar-profile {
  position: relative; /* Makes this a reference for absolute children */
  cursor: pointer; /* Optional: Changes the cursor on hover */
}
/* Ensures all text within the navbar-profile and its children is black */
.navbar-profile, .navbar-profile * {
    color: black; /* Sets text color to black */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  width: 200px; /* Adjust width as needed */
  max-height: auto;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: none;
  opacity: 0;
  padding: 0px 0px 0px 0px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  text-align: left;
  color: #000000 !important;
  text-decoration: none;
}

/* Show the dropdown menu with a transition */
.navbar-profile:hover .dropdown-menu {
  display: block;
  opacity: 1;
  text-decoration: none;
}

/* Style for individual dropdown items for better readability */
.dropdown-menu li {
  padding: 8px;
  list-style: none; 
  border-bottom: 1px solid #f0f0f0; 
  color: #000000 !important;
  text-decoration: none;
}

.dropdown-menu li:last-child {
  border-bottom: none;
  text-decoration: none; 
}

.dropdown-menu li:hover {
  text-decoration: none;
}


.navbar li {
  list-style-type: none; 
}

.navbar li a {
  text-decoration: none;  
  color: #000;  
}


.navbar li a:hover {
  color: #007bff;  
}

.profile-icon {
  width: 35px;
  height: 35px;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-text {
  background-color: #ffffff;
  padding: 40px; /* Increase padding for more space inside the popup */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 600px; /* Optional: Set max-width to limit popup width */
}

.popup-buttons {
  margin-top: 20px;
}

.confirm-btn,
.cancel-btn {
  padding: 12px 24px; /* Adjust button padding */
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.confirm-btn {
  background-color: #3f8ed7;
  color: #ffffff;
}

.cancel-btn {
  background-color: #e6e6e6;
  color: black;
}

.confirm-btn:hover,
.cancel-btn:hover {
  opacity: 0.8;
}
.restricted-badge-navbar {
  background-color: red;
  color: white;
  border-radius: 7px;
  padding: 4px 9px;
  font-size: 1.1rem;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 200px;
  z-index: 100;
  white-space: nowrap;
  width: 120px
}

.tooltip-custom {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  color: #000;
  padding: 12px 16px;
  border: 2px solid #000;
  border-radius: 8px;
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 250px;
  text-align: center;
  transform: translateX(-50%);
}

.tooltip-custom::after {
  content: "";
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
}

.tooltip-custom::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 18px solid #000;
  font-weight: 450;
}



@media (max-width: 768px) {
  .restricted-badge-navbar {
    font-size: 0.9rem;
    padding: 3px 8px;
  }
}

@media (max-width: 480px) {
  .restricted-badge-navbar {
    font-size: 0.8rem;
    padding: 2px 6px;
  }
}