/* SearchBox.css */

.search-box-container {
    position: relative;
    width: 100%;
  }
  
  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 200px; /* Optional: limit the height */
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.2); /* Border to distinguish the list */
  }
  