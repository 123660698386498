.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .alert {
    background-color: white;
    padding: 40px; /* Increase padding for larger size */
    border-radius: 8px; /* Increase border radius for rounded corners */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px; /* Limit maximum width to maintain readability */
    width: 100%; /* Ensure it takes full width of the container */
  }
  
  .button-container {
    margin-top: 20px; /* Increase margin between message and button */
  }
  
  button {
    padding: 10px 24px;
    background-color:  #e6e6e6;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

  