/* EditChoiceModal.css */
.ecm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
  }
  
  .ecm-modal {
    background-color: white;
    padding: 20px 30px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 2vh;
  }
  
  .ecm-modal-header {
    text-align: left;
    margin-bottom: 20px;
    position: relative;
  }
  
  .ecm-modal-title-wrapper {
    display: flex;
    align-items: center;
  }
  
  .ecm-modal-title {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--Colors-Text-color, #333);
    margin: 0;
  }
  
  .ecm-modal-subtitle {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--Colors-Text-color, #333);
    margin: 5px 0 0 0;
  }
  
  .ecm-close-button {
    position: absolute;
    top: -10px;
    right: -15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .ecm-modal-body {
    padding-top: 20px;
    text-align: center;
  }
  
  .ecm-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .ecm-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    margin-left: 10px;
  }
  
  .ecm-button-primary {
    background-color: #4899dc;
    color: white;
  }
  
  .ecm-button-primary:hover {
    background-color: #3780b8;
  }
  
  .ecm-button-secondary {
    background-color: #6ACD54;
    color: white;
  }
  
  .ecm-button-secondary:hover {
    background-color: #5bb647;
  }
  
  .ecm-button-cancel {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .ecm-button-cancel:hover {
    background-color: #bdbdbd;
  }
  