.footer {
  background: var(--BG-Dark, radial-gradient(194.79% 123.02% at 93.75% 92.19%, #102636 0%, #12090D 41.43%));
  color: #fff;
  padding: 1rem;
  text-align: center;
  position: relative;
  width: 100%;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start */
}

.footer-left, .footer-center, .footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Align address to the left */
.footer-left {
  align-items: flex-start;
  text-align: left; /* Align text to the left */
}

.footer-address {
  margin-bottom: 0.5rem;
}

.footer-address i {
  opacity: 0.9;
  margin-right: 0.7rem;
}

.footer-address a {
  opacity: 0.9;
  color: #fff;
  text-decoration: none;
}

/* Social media icons */
.footer-socials {
  display: flex;
  gap: 1rem;
  justify-content: flex-start; /* Align social icons to the start */
  margin-bottom: 0.5rem; /* Add space below social media links */
}

.footer-socials a {
  color: #4CAF50;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-socials a:hover {
  color: #fff;
}

/* Email link styling */
.footer-contact {
  margin-bottom: 0.5rem; /* Add the same spacing as other elements */
}

.footer-contact a {
  opacity: 0.9;
  color: #fff; /* Ensure the link is white, not blue */
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  text-decoration: underline; /* Underline on hover for consistency */
}

/* Center the copyright message */
.footer-center {
  opacity: 0.9;
  text-align: center;
  margin-left: 5%;
  align-items: center;
  justify-content: center;
}

/* Align the links to the right and add space between them */
.footer-right {
  align-items: flex-end;
  text-align: right; /* Align text to the right */
}

.footer-right h3 {
  font-size: medium;
  opacity: 0.7;
  margin-bottom: 1rem; /* Space below the heading */
}

.footer-right a {
  opacity: 0.9;
  color: #fff;
  text-decoration: none;
  display: block; /* Make each link a block to stack vertically */
  margin-bottom: 0.5rem; /* Adjust this value to increase/decrease the space between links */
}

.footer-right a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
  }

  .footer-left, .footer-center, .footer-right {
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
  }
}
