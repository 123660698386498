.contact-us {
  background: #e1f4e4;
  padding: 3rem 1rem;
}

.contact-us-info {
  display: flex;
  flex-direction: row; /* Default layout for larger screens */
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.contact-us-details {
  flex: 1;
  margin-right: 2rem;
}

.contact-us-details h2 {
  color: var(--Heading-Color, #102636);
  font-family: 'Inter', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px; /* 140% */
  letter-spacing: 0.83px;
  margin-bottom: 1.5rem;
}

.contact-description {
  color: var(--Heading-Color, #102636);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10%;
  margin-top: 10%;
}

.contact-detail {
  color: var(--Heading-Color, #102636);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.83px;
  margin-bottom: 1.5rem;
}

.address-indent {
  display: inline-block;
  margin-left: 20px;
  white-space: nowrap;
}

.contact-button {
  background: #6acd54; /* Button background color */
  color: black; /* Button text color */
  border: none; /* No border */
  padding: 0.75rem 1.5rem; /* Padding inside the button */
  font-size: 1rem; /* Font size for button text */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-transform: uppercase; /* Uppercase text */
  margin-top: 10%;
  font-weight: bold;
}

.contact-button:hover {
  background-color: #218838;
}

.contact-us-map {
  flex: 1;
  margin-left: 2rem;
  max-width: 800px;
  height: 500px;
  position: relative;
  display: block;
}

.azure-map {
  width: 100%;
  height: 100%;
}

.map-button {
  background: #6acd54;
  color: black;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.map-button:hover {
  background-color: #218838;
}

.contact-link {
  color: var(--Heading-Color, #102636);
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .contact-us-info {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center-align text */
  }

  .contact-us-details {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 2rem; /* Add bottom margin */
  }

  .contact-us-map {
    margin-left: 0; /* Remove left margin */
    max-width: 100%; /* Full width */
    height: 300px; /* Adjust height */
    width: 100%; /* Ensure full width */
  }

  .azure-map {
    height: 300px; /* Adjust height to ensure visibility */
  }
}
