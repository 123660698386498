:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

/* .main-container {
    overflow: hidden;
} */

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.nextBank {
  padding: 8px 30px !important;
  background-color: #0a66c2 !important;
  color: #ffffff !important;
}

.bankDetailsbuttons {
  margin: 650px 0px 0px 180px !important
}

.container-BankDetails-Homepage {
  position: absolute;
  width: 608px;
  height: 500px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, 0.09);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.group-home {
  position: relative;
  width: 208px;
  height: 208px;
  margin: 58px 0 0 200px;
  z-index: 4;
  overflow: hidden;
}

.Bankdetails-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../../Images/AddBankDetails.png) no-repeat center;
  background-size: 100% 100%;
  z-index: 5;
}

.Header-NoAccounts {
  display: block;
  position: relative;
  height: 14px;
  margin: 55px 0 0 179px;
  color: #000000;
  font-family: Lato, var(--default-font-family);
  font-size: 19.200000762939453px;
  font-weight: 900;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
}

.text-AddNewAccount {
  display: flex;
  position: relative;
  height: 11px;
  margin: 15px 0 0 0px;
  color: #646464;
  font-family: Lato, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
  justify-content: center;
}

.AddNewAccount {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  position: relative;
  width: 271px;
  height: 53px;
  margin: 28px 0 0 169px;
  padding: 8px 56px 8px 60px;
  background: #0a66c2;
  z-index: 2;
  border-radius: 5px;
  cursor: pointer;
}

.AddNewAccount-Btn {
  flex-shrink: 0;
  position: relative;
  height: 100%;
  color: #ffffff;
  font-family: Lato, var(--default-font-family);
  font-size: 19.200000762939453px;
  font-weight: 700;
  line-height: 14px;
  white-space: nowrap;
  z-index: 3;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.no-records-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.bankDetails {
  font-size: 28px;
  font-weight: 700;
}

.main-container {
  position: relative;
  width: 1243px;
  margin: 0 auto;
  font-size: 0px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 10px;
}

.bankInputs {
  margin: 12px 23px 10px 0px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
}

input {
  width: 100%;
}

.label {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
}

.PrefferedbankHeader {
  font-size: 19px;
  font-weight: 600;
}

.AddBank {
  color: #0a66c2;
  font-size: 15px;
  font-weight: 500;
}

.bankdetails {
  margin: 50px 20px 30px
}

.icon {
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.icon.edit-icon {
  color: black;
  /* Blue for edit */
}

.icon.delete-icon {
  color: black;
}

.icon:hover {
  opacity: 0.8;
}

.intialBankstep {
  margin: 607px -130px 0px 0px !important;
}

.property-card {
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: border 0.3s ease;
}

.property-card:hover {
  border: 1px solid lightgray;
}

.property-card.selected-card {
  border: 2px solid #0a66c2;

}

.goback {
  margin: 10px 9px 10px 613px;
}