.hero {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: left; /* Left-align text */
  padding: 2rem; /* Padding around the section */
  background: var(--BG-Dark, radial-gradient(194.79% 123.02% at 93.75% 92.19%, #102636 0%, black 41.43%));  /* Dark background color */
  color: white; /* Text color */
  position: relative; /* Relative positioning for child elements */
  overflow: hidden; /* Hide overflow content */
  height: 100vh; /* Full viewport height */
}

.hero-content {
  padding: 0 2rem; /* Padding for the content */
  z-index: 1; /* Ensure content is above the image */
}

.hero h1 {
  color: var(--White, #fff); /* White color for text */
  font-family: "Avenir Next", sans-serif; /* Font family */
  font-size: 12vw; /* Responsive font size */
  font-weight: 700; /* Bold font weight */
  line-height: 1.05; /* Line height */
  letter-spacing: -2px; /* Letter spacing */
  text-transform: uppercase; /* Uppercase text */
  margin: 1rem 0; /* Margin above and below the text */
}

.hero .green-gradient {
  background: radial-gradient(#7ae98a, #4aaa35);
  background-clip: text; /* Clip background to text */
  -webkit-background-clip: text; /* Webkit-specific background clip */
  -webkit-text-fill-color: transparent; /* Make text fill transparent */
  z-index: -1 !important; /* Set z-index to -1 to position the text behind the image */
  font-size: 14vw; /* Larger font size for the green text */
  margin: 1rem 0; /* Margin above and below the gradient text */
}

.hero .sub-text {
  color: rgba(255, 255, 255, 0.65); /* Subtext color with opacity */
  font-family: "Inter", sans-serif; /* Font family for subtext */
  font-size: 5vw; /* Responsive font size */
  font-weight: 400; /* Font weight for subtext */
  line-height: 6vw; /* Line height for subtext */
  letter-spacing: 0.5px; /* Letter spacing for subtext */
  margin: 3rem 0; /* Margin above and below the subtext */
}

.hero-button {
  background: #6acd54; /* Button background color */
  color: white; /* Button text color */
  border: none; /* No border */
  padding: 0.5rem 1rem; /* Padding inside the button */
  font-size: 0.8rem; /* Font size for button text */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-transform: uppercase; /* Uppercase text */
  font-weight: bold;
  margin-top: 2rem; /* Margin above the button */
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap; /* Ensure text does not wrap */
}

.hero-button:hover {
  opacity: 0.8; /* Slight opacity change on hover */
}

.hero-image {
  display: none; /* Hide the hero image on small screens */
}

@media (min-width: 768px) {
  .hero {
    flex-direction: row; /* Row layout for larger screens */
    justify-content: flex-start; /* Align content to the left */
    padding: 4rem 2rem; /* Padding for larger screens */
    height: 90vh; /* Auto height for larger screens */
  }

  .hero-content {
    max-width: 80%; /* Max width for content */
    margin-left: 3%; /* Margin to the left */
    text-align: left; /* Align text to the left */
  }

  .hero h1 {
    font-size: 4rem; /* Font size for larger screens */
  }

  .hero p {
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 1.5rem; /* Font size for subtext */
  }

  .hero-image {
    display: block; /* Show the hero image on larger screens */
    position: absolute; /* Absolute positioning */
    right: -10%; /* Adjust image position */
    bottom: -5%; /* Adjust image position */
    width: 50%; /* Width of the image container */
    z-index: 1; /* Ensure image is behind text */
  }

  .hero-button {
    font-size: 1rem; /* Increase font size for larger screens */
    padding: 0.75rem 1.5rem; /* Adjust padding for larger screens */
    width: auto; /* Allow width to adjust automatically */
  }
}

@media (min-width: 1024px) {
  .hero {
    padding: 6rem 4rem; /* Padding for extra large screens */
  }

  .hero h1 {
    font-size: 5vw; /* Font size for extra large screens */
  }

  .hero .green-gradient{
    font-size: 6vw; /* Larger font size for the green text */
  }

  .hero .sub-text{
    font-size: 20px; /* Font size for subtext */
    line-height: 20px;
  }

  .hero p {
    width: 70%;
    font-size: 1.75rem; /* Font size for subtext */
  }

  .hero-image {
    width: 110%; /* Width of the image container */
    right: -32%; /* Adjust image position */
    bottom: -35%; /* Adjust image position */
  }

  .hero-image img{
    width: 100%;
    height: auto;
  }

  .hero-button {
    font-size: 1.2rem; /* Increase font size for extra large screens */
    padding: 1rem 2rem; /* Adjust padding for extra large screens */
    width: auto; /* Allow width to adjust automatically */
  }
}

@media (max-width: 768px) {
  .hero-button {
    padding: 0.5rem 1rem; /* Reduce padding */
    font-size: 0.7rem; /* Reduce font size */
    width: auto; /* Allow width to adjust automatically */
  }
}
