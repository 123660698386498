.StrongPassword-H {
    font-weight: bold !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    font-size: 16px !important;
  }
  
  .password-list {
    list-style-type: none;
    padding-left: 0;
    padding-top: 10px;
  }
  
  .password-requirement {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    color: red;
  }
  
  .password-requirement-success {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    color: green;
  }
  
  .requirement-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  .createPassword{
    margin-top: 30px !important;
  }
  .btn-createPassword{
    width: 50%;
    margin-top: 30px !important;
    background: #0A66C2;
    color: white;
  }
  