/* ScheduleSummaryPopup.css */

/* 
   Make the entire screen darkened and show the summary content in the center.
   Adjust z-index as needed if you have other layers.
*/
.summary-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* The white "popup" box itself */
.summary-popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  width: 700px; /* Adjust as desired */
  max-height: 80vh; /* So it doesn’t overflow the screen */
  overflow-y: auto; /* Scroll if needed */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.summary-popup-content table {
    font-size: 12px;
}
