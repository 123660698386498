.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.sales-field{
  display: flex;
  direction: row-reverse;
  margin-top: 10px;
  margin-left: -10px;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.search-results {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 30%; /* Adjust the initial width as needed */
 
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a box shadow for better visibility */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f1f1f1;
}
/* Add this CSS to your style file or within a <style> tag in your HTML */
/* Add this CSS to your style file or within a <style> tag in your HTML */
.location-pin {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 60px;
  background-color: #6DA;
  border-radius: 10px 10px 0 0;
}

.location-pin:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #3498db transparent transparent transparent;
}
