.calendar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 95%;

  padding-left: 60px;
  padding-top: 40px;
}

.datepicker-container {
  margin-top: 20px;
  width: 320px; /* Adjusted width */
  margin-right: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between the buttons */
  margin-top: 20px; /* Adjusted margin to position the buttons */
  margin-left: 20px;
  margin-right: 60px;
}

.custom-button-primary {
  padding: 10px 20px;
  background-color: #4899dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.rbc-day-bg.rbc-off-range-bg {
  background-color: #f0f0f0;
  pointer-events: none; /* Disable click events */
}

.custom-button-primary:hover {
  background-color: #357bbd;
}

.calendar-container {
  flex-grow: 1;
}

/* General toolbar styling */
.toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}

/* Left section for date and today button */
.toolbar-left {
  display: flex;
  align-items: center;
}

.toolbar-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-right: 10px;
}

/* Styling for the Today button */
.today-button {
  background-color: #4899DC;
  border: none;
  color: black;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.today-button:hover {
  background-color: #387bb8;
  
}

/* Right section for navigation and view buttons */
.toolbar-right {
  display: flex;
  align-items: center;
  margin-bottom: -5px;
}

.toolbar-svg-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.toolbar-svg-container:hover svg {
  stroke: #000;
}

/* Styling for the Month/Week/Day buttons */
.toolbar-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 0.9rem;
  font-weight: bold;
}

.toolbar-button.active {
  background-color: #4899DC;
  color: white;
}

.toolbar-button:not(.active):hover {
  background-color: #f0f0f0;
}


.rbc-month-view {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.rbc-header {
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.rbc-date-cell {
  font-weight: bold;
}

.rbc-event {
  background-color: rgba(63, 142, 215, 0.4); /* Light blue with 40% opacity */
  color: #000000; /* Black text */
  border-left: 6px solid #3F8ED7; /* Left border only */
  padding: 5px;
  font-size: 0.875rem;
}

.rbc-today {
  background-color: #e0f7fa;
}

.rbc-off-range-bg {
  background-color: #f0f0f0;
}

.MuiPickerStaticWrapper-root {
  width: 300px; /* Adjusted width to prevent overflow */
  height: auto;
}

.MuiPickersCalendarHeader-root {
  font-size: 0.9rem; /* Reduce header font size if needed */
}

.MuiPickersDay-root {
  width: 20px; /* Adjust day cell size */
  height: 30px; /* Adjust day cell size */
  font-size: 0.75rem; /* Adjust font size for the day numbers */
}

.MuiPickersCalendarHeader-label {
  font-size: 0.85rem; /* Smaller font size for the month label */
}

.MuiPickersSlideTransition-root {
  width: 100%;
}
