.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .alert {
    background-color: white;
    padding: 15px;
    border-radius: 8px; 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 350px;
    width: 100%;
    font-size: 15px;
    color: black; 
  }
  
  .button-container {
    margin-top: 20px; 
    display: flex;
    justify-content: center; 
}


.accept-button {
    padding: 10px 24px;
    background-color: #28a745; 
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 8px;
}

.reject-button {
    padding: 10px 24px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 8px;
}

textarea {
    height: 70px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    max-height: 500px;
    width: 300px;
    min-height: 100px;
}

.error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}
.close-icon {
    position: absolute;
    top: 2px;
    right: 5px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}
/* styles.css */
.custom-button {
    background-color: #0A66C2;  
    color: white;          
    padding: 5px 10px;      
    border: none;           
    border-radius: 3px;    
    cursor: pointer;         
    font-size: 14px;        
    transition: background-color 0.3s;
}
.tab-titles {
    display: flex;
    justify-content: flex-start; 
    gap: 60px;                   
    padding-left: 60px;   
    margin-top: 30px;          
}

.tab-titles button {
    padding: 10px 20px;          
    border: none;               
    cursor: pointer;            
    color: black;               
    background-color: transparent; 
}

.tab-titles button.active {
    background-color: lightgray; 
    color: black;                
}

.tab-content {
    margin-top: 20px;            
    padding: 10px;               
}

.tab-count {
    background-color: lightgray; 
    color: black;                
    padding: 3px 8px;          
    border-radius: 8px;        
    margin-left: 8px;          
}

.active-count {
    background-color: white;    
    color: black;               
}
