/* Container for the services section */
.services {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #e1f4e4; /* Background color */
  color: var(--Heading-Color, #102636); /* Text color */
}

/* Container for the service blocks */
.services-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
}

/* Service block styling */
.service-block {
  display: flex;
  flex-direction: column; /* Default to column layout */
  align-items: center;
  margin-bottom: 2rem;
}

@media (min-width: 1200px) {
  .service-block {
    flex-direction: row; /* Row layout for larger screens */
    justify-content: flex-start; /* Align items to the start */
    align-items: flex-end;
  }

  .service-block.reverse {
    flex-direction: row-reverse; /* Reverse row layout for alternating position */
  }
}

/* Styling for the image */
.service-image {
  width: 100%;
  max-width: 600px; /* Adjust image size */
  height: 600px; /* Set height to ensure square aspect ratio */
  object-fit: cover; /* Ensure the image covers the square without distortion */
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .service-image {
    max-width: 300px; /* Adjust max width for smaller screens */
    height: 300px; /* Adjust height to maintain square aspect ratio */
  }
}

@media (min-width: 768px) {
  .service-image {
    margin-bottom: 0;
  }
}

/* Ensure the second image has the same dimensions as the other images */
.service-block:nth-child(2) .service-image {
  width: 100%;
  max-width: 600px !important;
  height: 600px !important;
  object-fit: cover; /* Crop the top and bottom if necessary */
}

@media (max-width: 767px) {
  .service-block:nth-child(2) .service-image {
    max-width: 300px !important; /* Adjust max width for smaller screens */
    height: 300px !important; /* Adjust height to maintain square aspect ratio */
  }
}

/* Styling for the content */
.services-content {
  max-width: 700px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align text to the bottom */
  padding-left: 1rem; /* Add padding between image and content */
}

@media (min-width: 1200px) {
  .services-content {
    padding-left: 2rem; /* Add more padding for larger screens */
  }

  .service-block.reverse .services-content {
    padding-left: 0;
    padding-right: 2rem; /* Adjust padding for reversed layout */
  }
}

/* Heading styles */
.services-content h2 {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 125% */
  letter-spacing: 0.3px;
  margin-bottom: 5%;
  color: var(--Heading-Color, #102636); /* Heading color */
}

/* Subtext styles */
.services-content p {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.3px;
  color: var(--Heading-Color, #102636); /* Paragraph color */
  margin-bottom: 2rem;
}

.services-content .learn-more {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #0066ff; /* Link color */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

/* Learn More button styles */
.services-content .learn-more {
  font-family: 'Inter', sans-serif;
  font-size: 16px; /* Updated font size */
  font-weight: 600; /* Updated font weight */
  line-height: 26px; /* Updated line height */
  letter-spacing: 0.5px; /* Updated letter spacing */
  color: var(--Link, #0070C9); /* Updated link color */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
