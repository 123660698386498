.sideHeader {
    cursor: pointer;
    display: block;
    padding: 10px;
    margin: 5px 0;
    color: black; /* Default color */
}

.sideHeader.active {
    color: #007bff; /* Blue color for active section */
}

/* .entity-info-container {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
} */

/* .form-group {
    margin-bottom: 15px;
}

.save-changes-button, .change-password-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.password-input-container {
    display: flex;
    align-items: center;
}

.password-input-container input {
    flex: 1;
}

.password-input-container button {
    background: none;
    border: none;
    cursor: pointer;
}

.password-requirements ul {
    list-style-type: none;
    padding-left: 0;
}

.password-requirements li::before {
    content: '• ';
    color: #007bff;
} */

/* SettingEntityInfo.css */
.confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    z-index: 1000; /* Ensure the popup is above other content */
}

.confirmation-popup-content {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    height: 150px;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.confirmation-popup-content p {
    margin-bottom: 20px;
}

.confirmation-popup-content button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Confirm:hover {
    background-color: #5aa949;
}
.Cancel:hover {
    background-color: #A9A9A9;
}

.Confirm{
 background-color: #6BCD55;
}
.Cancel{
    background-color: lightgray;
   }
   .container_fluid {
	background-color: #F5F5F5;
	min-height: 100vh;
	/* Ensures the background color covers the entire viewport height */
}

.settingheader {
	color: #000;
	font-family: Lato;
	font-size: var(--Font-Size-font-size-h4, 27.65px);
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	width: 101px;
	height: 33px;
	margin: 40px 22px;
}

.Entityinfo {
	border-radius: 10px;
	background: #FFF;
	box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
	width: 350px;
	height: 650px;
}

.personinfoheader {
	margin-left: -11px;
	padding: 30px 25px 10px;
	color: var(--Grays-and-Neutrals-color-gray-500, #5C5C5C);
	font-family: Lato;
	font-size: var(--Font-Size-font-size-paragraph, 16px);
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.sideHeader {
	display: block;
	text-align: left;
	margin-top: 5px;
	padding: 3px 70px;
	color: #989898;
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

/* EntityInformationForm.css */
.entity-info-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.entity-info-container h2 {
	font-size: 1.5rem;
	margin-bottom: 10px;
}

.entity-info-container hr {
	margin-bottom: 20px;
}

.entity-form {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.form-entity {
	flex: 1 1 45%;
	/* Adjust this value to control the width of form groups */
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.form-entity label {
	margin-bottom: 5px;
	font-weight: bold;
	text-align: left;
}

.form-entity input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 370px;
}

.form-entity input[name="MobileNumber"],
.form-entity input[name="OfficeNumber"] {
    padding: 10px;
    border: 0px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 370px;
}

.save-changes-button {
	background-color: #28a745;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	align-self: flex-end;
	margin-top: 20px;
}

.save-changes-button:hover {
	background-color: #218838;
}

.profile-picture {
	flex: 1 1 100%;
	display: flex;
	justify-content: start;
	margin-bottom: 20px;
}

.profile-picture img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.password-input-container {
	display: flex;
	align-items: center;
	position: relative;
}

.password-input-container input {
	padding: 10px;
	padding-right: 40px;
	/* Adjust for button size */
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1rem;
}

.password-input-container button {
	position: absolute;
	left: 300px;
	background: none;
	border: none;
	cursor: pointer;
}

.password-requirements {
	font-size: 0.875rem;
}

.password-requirements ul {
	list-style-type: none;
	padding-left: 0;
}

.password-requirements li {
	margin-bottom: 5px;
	display: flex;
	align-items: center;
}

.password-requirements li::before {
	content: '✓';
	color: green;
	margin-right: 10px;
}

.change-password-button {
	background-color: #3F8ED7;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	align-self: flex-start;
	width: 246px;
	gap: 8px;
	padding: var(--Font-Size-font-size-paragraph, 16px) 8px;


}

.change-password-button:hover {
	background-color: #3F8ED7;
}

.StrongPassword-H {
	color: var(--Colors-Text-color, #333);
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 1px 300px 2px 2px;
    text-align: left;
}
.Password-Setting{
	margin-bottom: 25px;
}
.pricing-info-container {
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    max-width: 700px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.pricing-info-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 10px;
}

.pricing-info-container p {
    font-size: 16px;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
}

.pricing-info-container h3 {
    text-align: left;
    color: #333;
    margin-bottom: 10px;
}

.pricing-info-container ul {
    list-style-type: none;
    padding: 0;
}

.pricing-info-container li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.pricing-info-container li:last-child {
    border-bottom: none;
}

.price {
    color: #2c3e50;
    font-size: 18px;
}

.description {
  font-size: 18px;
  color: #423e3e;
  max-width: 450px;
  margin-left: 110px;
}
.dialog-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
  }

  .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.dialog-box p {
  margin-bottom: 20px;
}

.dialog-box button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.dialog-box button:hover {
  background: #0056b3;
}

.dialog-box h2 {
	text-align: left;
  }
  
  .dialog-box {
	background: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
  }
  
  .dialog-box p {
	margin-bottom: 20px;
  }
  
  .dialog-box button {
	margin: 0 10px;
	padding: 10px 20px;
	border: none;
	background: #007bff;
	color: white;
	border-radius: 5px;
	cursor: pointer;
  }
  
  .dialog-box button:hover {
	background: #0056b3;
  }

  :root {
    --CustomPhoneInput-color--focus: black;
    --CustomPhoneInputInternationalIconPhone-opacity: 0.8;
    --CustomPhoneInputInternationalIconGlobe-opacity: 0.65;
    --CustomPhoneInputCountrySelect-marginRight: 0.35em;
    --CustomPhoneInputCountrySelectArrow-width: 0.3em;
    --CustomPhoneInputCountrySelectArrow-marginLeft: var(--CustomPhoneInputCountrySelect-marginRight);
    --CustomPhoneInputCountrySelectArrow-borderWidth: 1px;
    --CustomPhoneInputCountrySelectArrow-opacity: 0.45;
    --CustomPhoneInputCountrySelectArrow-color: currentColor;
    --CustomPhoneInputCountrySelectArrow-color--focus: var(--CustomPhoneInput-color--focus);
    --CustomPhoneInputCountrySelectArrow-transform: rotate(45deg);
    --CustomPhoneInputCountryFlag-aspectRatio: 1.5;
    --CustomPhoneInputCountryFlag-height: 1em;
    --CustomPhoneInputCountryFlag-borderWidth: 1px;
    --CustomPhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
    --CustomPhoneInputCountryFlag-borderColor--focus: var(--CustomPhoneInput-color--focus);
    --CustomPhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
  }
  
  .CustomPhoneInput {
    display: flex;
    align-items: center;
    padding: 0.01em;
    border-radius: 4px;
    width: 370px;
    border: 1px #ccc solid;
  }
  
  .CustomPhoneInput:focus-within {
    border-color: black;
    border-width: 0px;
    box-shadow: 0 0 0 2px black;
  }
  
  .CustomPhoneInputInput {
    flex: 1;
    min-width: 0;
    border: none;
    outline: none;
  }
  
  .CustomPhoneInputCountryIcon {
    width: calc(var(--CustomPhoneInputCountryFlag-height) * var(--CustomPhoneInputCountryFlag-aspectRatio));
    height: var(--CustomPhoneInputCountryFlag-height);
  }
  
  .CustomPhoneInputCountryIcon--square {
    width: var(--CustomPhoneInputCountryFlag-height);
  }
  
  .CustomPhoneInputCountryIcon--border {
    background-color: var(--CustomPhoneInputCountryFlag-backgroundColor--loading);
    box-shadow: 0 0 0 var(--CustomPhoneInputCountryFlag-borderWidth) var(--CustomPhoneInputCountryFlag-borderColor),
      inset 0 0 0 var(--CustomPhoneInputCountryFlag-borderWidth) var(--CustomPhoneInputCountryFlag-borderColor);
  }
  
  .CustomPhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .CustomPhoneInputInternationalIconPhone {
    opacity: var(--CustomPhoneInputInternationalIconPhone-opacity);
  }
  
  .CustomPhoneInputInternationalIconGlobe {
    opacity: var(--CustomPhoneInputInternationalIconGlobe-opacity);
  }
  
  .CustomPhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--CustomPhoneInputCountrySelect-marginRight);
    padding-left: 10px;
  }
  
  .CustomPhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .CustomPhoneInputCountrySelect[disabled],
  .CustomPhoneInputCountrySelect[readonly] {
    cursor: default;
  }
  
  .CustomPhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--CustomPhoneInputCountrySelectArrow-width);
    height: var(--CustomPhoneInputCountrySelectArrow-width);
    margin-left: var(--CustomPhoneInputCountrySelectArrow-marginLeft);
    border-color: var(--CustomPhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--CustomPhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var (--CustomPhoneInputCountrySelectArrow-borderWidth);
    transform: var(--CustomPhoneInputCountrySelectArrow-transform);
    opacity: var(--CustomPhoneInputCountrySelectArrow-opacity);
  }
  
  .CustomPhoneInputCountrySelect:focus + .CustomPhoneInputCountryIcon + .CustomPhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--CustomPhoneInputCountrySelectArrow-color--focus);
  }
  
  .CustomPhoneInputCountrySelect:focus + .CustomPhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--CustomPhoneInputCountryFlag-borderWidth) var (--CustomPhoneInputCountryFlag-borderColor--focus),
      inset 0 0 0 var(--CustomPhoneInputCountryFlag-borderWidth) var (--CustomPhoneInputCountryFlag-borderColor--focus);
  }
  
  .CustomPhoneInputCountrySelect:focus + .CustomPhoneInputCountryIcon .CustomPhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--CustomPhoneInputCountrySelectArrow-color--focus);
  }
  