.background{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background:black !important;
  background-size: cover;
}

:root {
  --BG-Dark: radial-gradient(194.79% 123.02% at 93.75% 92.19%, #102636 0%, #12090D 41.43%);
}



.privacy-policy {
  font-family: 'Arial', sans-serif; /* Match font style with footer */
  padding: 2rem;
  background-color: black;
  color: rgba(255, 255, 255, 0.86); /* Set text opacity to 0.9 */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  max-width: 800px; /* Control the width of the content */
  margin: 0 auto;
}

.privacy-policy h1 {
  opacity: 1;
  font-size: 2.5rem; /* Keep font size */
  margin-bottom: 1rem;
  text-align: center;
}

.privacy-policy h2 {
  text-align: left;
  font-size: 1.75rem; /* Keep font size */
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.privacy-policy p, .privacy-policy ul {
  text-align:left;
  font-size: 1rem; /* Keep font size */
  line-height: 1.5; /* Keep line height */
  margin-bottom: 1rem;
}

.privacy-policy a {
  color: #6acd54; /* Match link color with next opacity */
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy ul {
  padding-left: 1.5rem;
}

.privacy-policy ul li {
  list-style-type: disc;
  margin-bottom: 0.5rem;
}
