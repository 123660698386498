@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap");

body {
  margin: 0;
  line-height: normal;
  background-color: white !important;
}

.loginfirst {
  margin: 7rem 3rem;
}

.aval-custom-recurrence-summary {
  font-size: 14px;
  text-align: left;
  color: #666363;
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px 0 10px 0;
}

.detect-wrapper {
  left: 60px;
  position: absolute;
  top: 800px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 60px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 19.2px;
  color: #333;
  flex-shrink: 1;
}

.back-wrapper {
  right: 250px;
  position: absolute;
  top: 750px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 60px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 19.2px;
  color: #333;
  flex-shrink: 1;
}

.detectBtn,
.clearBtn {
  right: auto;
  left: 60px;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff !important;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 60px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 19.2px;
  color: #333;
  flex-shrink: 1;
  z-index: 10000px !important;
}

.detectBtn {
  top: 800px;
  /* Same top value as the .next-wrapper */
}

.clearBtn {
  top: 740px;
  /* Positioned above the detectBtn */
}

.login-form {
  /* width: 360px; */
  /* margin: 50px auto; */
  padding: 85px;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.caption {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.secondary {
  background-color: var(--Colors-Secondary-color, #6bcd55) !important;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  display: inline-flex !important;
  padding: 11px 45px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 3px;
}

.css-aw0cy1-MuiTypography-root-MuiDialogTitle-root {
  color: var(--Colors-Primary-color, #3f8ed7) !important;
  font-family: Montserrat !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 60%;
  min-height: 30%;
  border-radius: 5px;
  background: #fff;
}

.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  border-bottom: thin;
}

.subheaders {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
}

.subcontent {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: left;
}

.half {

  &,
  & .container>.row {
    height: 100vh;
  }

  .imgdiv {
    width: 60%;

    @include media-breakpoint-down(md) {
      height: 500px;
    }
  }

  .bgstyle {
    /* background: url(<path-to-image>) lightgray -368.99px 0px / 130.629% 100% no-repeat; */
  }

  .contents {
    background: #fff;
    width: 40%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .bg {
    background-size: cover;
    background-position: center;
  }

  .btn {
    height: 54px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.login {
  margin-top: -140px;
}

.textleft {
  text-align: left;
}

.loginheader {
  font-family: Montserrat;
  margin-top: 50px;
  color: var(--Colors-Text-color, #333);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginDescription {
  color: #747474;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 146.64%;
  /* 26.395px */
}

.dashboardheader {
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
}

.formbox {
  margin: 80px 0px 16px;
}

.userName {
  color: #000;
}

.mandatorymark {
  color: #f00;
}

.loginlabel {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.remember {
  text-align: start;
}

.forgot-pass {
  text-align: end;
}

.joinus {
  color: var(--Colors-Text-color, #333);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Lato;
}

.joingeo {
  color: var(--Colors-Primary-color, #3f8ed7);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  font-family: Lato;
}

.form-control {
  display: flex;
  /* width: 461px; */
  height: 50px;
  padding: 8px 8px 8px 24px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid Colors/Storke color;
  background: #fff;
}

.form-control {
  color: #b7b7b7;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btnn-Secondary {
  display: flex;
  width: 194px;
  height: 56px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--Colors-Secondary-color, #6bcd55);
  float: right;
  margin-right: -42px;
}

.entity {
  overflow: hidden;
  max-height: 100%;
  min-height: 100%;
}

.nextBtn {
  width: 194px;
  height: 56px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  background: #0A66C2;
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.autonextBtn {
  height: 56px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #0A66C2;
  color: white;
}

.btnn-primary {
  display: flex;
  height: 50px;
  width: 100%;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background: #0A66C2;
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 80px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.range-input {
  -webkit-appearance: none;
  width: 100%;
  height: 9px;
  border-radius: 5px;
  /* Rounded corners */
  background: linear-gradient(to right, green 0%, rgb(12, 12, 12) 1%);
  outline: none;
}

.contain {
  margin: 3rem 3rem;
}

.registersteps {
  /* margin-top: 28px; */
  color: #aaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 146.64%;
  text-align: start;
  padding: 0px 82px;
}

.otp-digit {
  color: #333;
  width: 48px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-color: darkgrey;
  border-top: none;
  border-left: none;
  border-right: none;
}

.selected-image {
  border: 2px solid green;
}

.lotmanagment {
  margin: 4rem 0rem;
  padding: 4rem 2rem;
  background-color: #f5f5f5;
  width: 100%;
  min-height: auto;
}

.maplocation {
  flex-direction: row;
  justify-content: center;
  display: flow;
  flex-direction: row;
}

.image-container {
  position: relative;
}

.image-checkbox {
  top: 10%;
  right: 10%;
  position: absolute;
}

.custom-paper-class {
  min-width: 35%;
  min-height: 35%;
  padding: "0.5rem 5rem";
  border-radius: "5px";
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #6acd54 !important;
}

.span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
  font-size: "18px" !important;
}

.css-37hgmf-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: rgba(51, 51, 51, 1) !important;
}

.bnkDetailsForm .form-group {
  text-align: left;
}

.scheduleavailability {
  display: flex;
}

.days {
  width: 200px;
  font-size: 19px;
  padding: 22px;
  align-items: inherit;
  text-align: left;
  display: block;
  font-weight: 700;
  margin: 0px 15px 0px 0px;
}

.daystextfield {
  display: flex;
  padding: 0px 0px 0px 15px;
}

.viewHeader {
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: left;
  margin-bottom: 15px;
}

.listview {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 10px;
}

.listviewheader {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: rgba(108, 108, 108, 1);
}

.listviewdata {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
}

.viewtable {
  align-items: center;
  padding: 15px 12px 15px 12px;
  background-color: white;
  height: 8rem;
}

.stepperrow {
  background-color: #fff;
}

.finishbtn {
  width: 194px;
  height: 56px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  background: rgb(215, 214, 214);
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  margin-right: 10px;
}

.secondary {
  background: var(--Colors-Primary-color, rgba(63, 142, 215, 1)) !important;
  color: white !important;
  font-family: Montserrat !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  display: inline-flex !important;
  padding: 11px 45px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 3px;
}

.mHisf {
  border-bottom: none !important;
}

.kgOwiN:not(:last-of-type) {
  border-bottom-style: none !important;
}

.popup-collection-container {
  z-index: 9999 !important;
}

.markoption {
  font-size: 18px;
  font-weight: 500;
}

.ul {
  width: 20px;
  text-align: left;
}

.input-active {
  color: rgba(0, 0, 0, 1);
}

.lotmanagement {
  margin: 80px 37px;
}

.forgotpasswordbutton {
  margin-top: 25% !important;
  width: 100%;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  background: #6acd54;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
}

.nextbtninmap {
  top: 73%;
  position: absolute;
  left: 83%;
}

.mapoverlay {
  color: white;
  overflow: overlay;
  z-index: 99999;
  position: absolute;
  top: 14%;
  left: 27%;
}

.backconfirmbtn {
  top: 75%;
  position: absolute;
  /* text-align: left; */
  right: 7%;
}

.map-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1 !important;
  /* Ensure map is behind other UI elements */
}

.ui-elements {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2 !important;
  /* Ensure these elements are above the map */
}

.snackbar-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.snackbar-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.snackbar-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.map-child {
  position: absolute;
  top: -7px;
  object-fit: cover;
  /* flex-direction: row; */
  justify-content: center;
  display: flow;
  flex-direction: row;
  width: 100vw;
  height: 100vh !important;
}

.material-symbolsquestion-mark-icon {
  width: 19.1px;
  position: relative;
  height: 19.1px;
  overflow: hidden;
  flex-shrink: 0;
}

.material-symbolsquestion-mark-wrapper {
  position: absolute;
  box-shadow: 0px 2.8363637924194336px 2.84px rgba(0, 0, 0, 0.25);
  border-radius: 39px;
  background-color: #fff;
  width: 39px;
  height: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13.7px;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  top: 111px;
  left: 375px;
  z-index: 10;
  cursor: pointer;
}

.next {
  position: relative;
  line-height: 33px;
}

.next-wrapper {
  right: 60px;
  position: absolute;
  top: 84vh !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 50px;
  width: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 17.2px;
  color: #333;
  flex-shrink: 1;
  overflow: hidden;
  /* height: 100vh;
  width: 100vw; */

}


.autosave1-wrapper {
  right: 220px;
  position: absolute;
  top: 84vh !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #6acd54;
  height: 50px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 17.2px;
  color: #333;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0;
}

.back-wrapper {
  right: 140px;
  position: absolute;
  top: 84vh !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 50px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 17.2px;
  color: #333;
  flex-shrink: 0;
  /* Prevent shrinking */
  overflow: hidden;
  /* Ensure no content spills out */
  padding: 0;
  /* Remove any padding */
}


.icround-gps-fixed-icon {
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}

.icround-gps-fixed-wrapper {
  position: absolute;
  top: 768px;
  left: 1854px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.frame-child {
  width: 18px;
  position: relative;
  height: 18px;
}

.group-parent {
  position: absolute;
  top: 825px;
  left: 1854px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  width: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 5px;
  box-sizing: border-box;
  gap: 16px;
}

.enter-your-address {
  flex: 1;
  position: relative;
  line-height: 33px;
}

.capa-1-icon {
  width: 21px;
  position: relative;
  height: 21px;
  overflow: hidden;
  flex-shrink: 0;
}

.enter-your-address-parent {
  width: 450px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  box-sizing: border-box;
  gap: 16px;
}

.frame-inner {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0px;
}

.component-1-inner {
  width: 450px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  box-sizing: border-box;
}

.image-6-icon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}

.image-6-parent {
  width: 450px;
  background-color: #fff;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  box-sizing: border-box;
  gap: 16px;
}

.component-1 {
  top: 108px;
  height: 53px;
  justify-content: flex-start;
  position: absolute;
  right: 60px;
}

.vector-icon {
  left: -10px;
  top: 5px;
  width: 7px;
  position: relative;
  height: 13px;
}

.locate-your-property {
  color: #333;
}

.in-progress {
  position: relative;
  font-size: 13.33px;
  line-height: 33px;
  color: #3f8ed7;
}

.of-6-locate-your-property-parent {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
}

.frame-wrapper {
  width: 279px;
  position: relative;
  height: 12px;
}

.vector-parent {
  align-self: stretch;
  background-color: #fff;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 20px;
  box-sizing: border-box;
  gap: 16px;
  width: 20%;
  z-index: 10000px !important;
}

.frame-child1 {
  width: 7px;
  position: relative;
  height: 13px;
  opacity: 0;
}

.of-6-container1 {
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 33px;
}

.of-6-mark-pick-up-and-drop-o-wrapper {
  width: 276px;
  position: relative;
  height: 12px;
}

.of-6-mark-parking-spaces-wrapper {
  width: 201px;
  position: relative;
  height: 12px;
}

.of-6-enter-bank-details-wrapper {
  width: 185px;
  position: relative;
  height: 12px;
}

.of-6-review-and-confirm-wrapper {
  width: 196px;
  position: relative;
  height: 12px;
}

.component-2 {
  position: absolute;
  top: 56px;
  left: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 470px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
  text-align: center;
}

.map {
  position: relative;
  /* top: 6px; */
  left: 0px;
  width: 100%;
  height: 60vh;
  font-size: 16px;
  color: #5c5c5c;
}

.my-properties-child {
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  width: 100%;
  height: 80px;
}

.group-child {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  background-color: #3f8ed7;
  width: 53px;
  height: 53px;
}

.jd {
  position: absolute;
  top: 14px;
  left: 14px;
}

.ellipse-parent {
  position: absolute;
  top: 13px;
  left: 1685px;
  width: 53px;
  height: 53px;
  color: #fff;
}

.screenshot-2024-03-07-at-1143 {
  position: absolute;
  top: 16px;
  left: calc(50% - 778px);
  width: 188px;
  height: 47px;
  object-fit: cover;
}

.dashboard {
  position: absolute;
  top: 28px;
  left: 705px;
  font-weight: 500;
}

.my-properties1 {
  position: absolute;
  top: 28px;
  left: 904px;
  color: #3f8ed7;
}

.payments {
  position: absolute;
  top: 28px;
  left: 1129px;
  font-weight: 500;
}

.my-properties-item {
  position: absolute;
  top: 75px;
  left: 901px;
  background-color: #3f8ed7;
  width: 131px;
  height: 5px;
}

.my-properties {
  width: 100%;
  position: relative;

  /* height: 1080px;
	overflow: hidden;
	text-align: left; */
  font-size: 20px;
  color: #333;
  font-family: Lato;
}

.portalPopupOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
}

.group-icon {
  position: absolute;
  top: 42px;
  left: 229px;
  width: 147px;
  height: 161.4px;
}

.locate-property {
  position: absolute;
  top: 219px;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0.18em;
  line-height: 24px;
  white-space: nowrap;
}

.group-container {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #3f8ed7;
  width: 605px;
  height: 272px;
  color: #fff;
}

.to-mark-pick-up {
  position: absolute;
  top: calc(50% + 10.5px);
  left: 30px;
  line-height: 24px;
  display: inline-block;
  width: 545px;
  text-align: center;
}

.close {
  position: relative;
  line-height: 24px;
}

.close-wrapper {
  position: absolute;
  top: 458px;
  left: calc(50% - 81.5px);
  border-radius: 5px;
  background-color: #e6e6e6;
  width: 163px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 8px;
  box-sizing: border-box;
}

.accordianparent {
  border-radius: 10px !important;
}

.frame-parent {
  width: 605px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  height: 534px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-family: Lato;
}

.confirm-property-location {
  position: absolute;
  top: 20px;
  left: 100px;
  font-size: 23.04px;
  line-height: 33px;
}

.marker_confirmation {
  position: absolute;
  top: 25px;
  left: 70px;
  font-size: 23.04px;
  line-height: 33px;
}

.confirm-property-location1 {
  font-size: 20.04px;
  left: 52px;
  line-height: 33px;
  position: absolute;
  top: 58px;
}

.Address_field {
  padding: 5px 0px 0px 5px;
  margin: 10px 10px;
}

.before-proceeding-please {
  position: absolute;
  top: 90px;
  left: 30px;
  line-height: 24px;
  display: inline-block;
  width: 431px;
}

.address {
  position: absolute;
  top: 70px;
  left: 30px;
  line-height: 24px;
  display: inline-block;
  width: 431px;
}

.are-you-sure {
  position: absolute;
  top: 124px;
  left: 30px;
  line-height: 24px;
}

.yes-continue {
  position: relative;
  line-height: 24px;
}

.yes-continue-wrapper {
  position: absolute;
  top: 179px;
  left: 260px;
  border-radius: 5px;
  background-color: #3f8ed7;
  width: 157px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 8px;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
}

.no-go-back-wrapper {
  position: absolute;
  top: 179px;
  left: 82px;
  border-radius: 5px;
  background-color: #e6e6e6;
  width: 157px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 8px;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
}

.confirm-property-location-parent {
  width: 491px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  height: 260px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Lato;
}

.frame-child {
  height: 35%;
  position: absolute;
  top: 0px;
  left: calc(50% - 237px);
  max-height: 100%;
  width: 474px;
}

.marker-details {
  position: absolute;
  top: 393px;
  left: 30px;
  font-size: 23.04px;
  line-height: 33px;
  color: #000;
}

.latitude-377749-n {
  margin: 0;
}

.latitude-377749-n-container {
  position: absolute;
  top: 431px;
  left: 30px;
  line-height: 24px;
  color: #5c5c5c;
}

.pue-pick {
  flex: 1;
  position: relative;
}

.frame-item {
  width: 17px;
  position: relative;
  height: 9px;
}

.pue-pick-up-entrance-parent {
  position: absolute;
  height: 5%;
  width: 87.34%;
  top: 54.65%;
  right: 6.33%;
  bottom: 40.36%;
  left: 6.33%;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #898989;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 24px;
  gap: 8px;
}

.write-here-wrapper {
  position: absolute;
  height: 19.58%;
  width: 87.34%;
  top: 66.23%;
  right: 6.33%;
  bottom: 14.19%;
  left: 6.33%;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #898989;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 24px 8px;
}

.span {
  color: #ff0000;
}

.purpose-of-marker-container {
  position: absolute;
  top: 52.25%;
  left: 6.33%;
}

.notes {
  position: absolute;
  top: 63.84%;
  left: 6.33%;
}

.illustration-icon {
  position: absolute;
  top: 64px;
  left: 29px;
  width: 415px;
  height: 276px;
  overflow: hidden;
}

.save {
  position: relative;
  line-height: 24px;
}

.save-wrapper {
  position: absolute;
  top: 916px;
  left: 29px;
  border-radius: 5px;
  background-color: #3f8ed7;
  width: 415px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 8px;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
}

.vector-parent {
  width: 100%;
  top: 14px;
  position: relative;
  background-color: #fff;
  /* border-top: 1px solid #ccc; */
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #333;
  font-family: Lato;
  height: 50px;
  border-radius: 10px !important;
  height: min-content;
  left: 50px;
  z-index: 10000px !important;
  padding: 6px !important;
}

.AccordName {
  padding: 11px 15px !important;
}

.AccordName.selected {
  padding: 11px 15px !important;
  background-color: #e5f3ff !important;
}

.vector-parent1 {
  width: 20%;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #333;
  font-family: Lato;
  height: 92vh;
  align-self: stretch;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 20px;
  box-sizing: border-box;
  gap: 16px;
}

.vector-child {
  width: 100%;
  top: -9px;
  position: relative;
  background-color: #fff;
  /* border-top: 1px solid #ccc; */
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #333;
  font-family: Lato;
  height: 50px;
  border-radius: 10px !important;
  height: min-content;
  left: 12px;
  z-index: 10000px !important;
  padding: 6px !important;
}

/* my-properties screen  css start from here*/

.my-properties-child {
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  width: 100%;
  height: 80px;
}

.group-child {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  background-color: #3f8ed7;
  width: 53px;
  height: 53px;
}

.jd {
  position: absolute;
  top: 14px;
  left: 14px;
}

.ellipse-parent {
  position: absolute;
  top: 13px;
  left: 1685px;
  width: 53px;
  height: 53px;
  text-align: left;
  color: #fff;
}

.screenshot-2024-03-07-at-1143 {
  position: absolute;
  top: 16px;
  left: calc(50% - 778px);
  width: 188px;
  height: 47px;
  object-fit: cover;
}

.dashboard {
  position: absolute;
  top: 28px;
  left: 705px;
  font-weight: 500;
  text-align: left;
}

.my-properties1 {
  position: absolute;
  top: 28px;
  left: 904px;
  color: #3f8ed7;
  text-align: left;
}

.payments {
  position: absolute;
  top: 28px;
  left: 1129px;
  font-weight: 500;
  text-align: left;
}

.my-properties-item {
  position: absolute;
  top: 75px;
  left: 901px;
  background-color: #3f8ed7;
  width: 131px;
  height: 5px;
}

.please-select-one {
  position: absolute;
  top: 30px;
  left: calc(50% - 382px);
  font-size: 22px;
  line-height: 144.36%;
  font-weight: 600;
  font-family: Montserrat;
  display: inline-block;
  width: 762px;
  text-align: center;
}

.please-select-checkbox {
  position: absolute;
  top: 1050px;
  left: calc(50% - 382px);
  font-size: 18px;
  line-height: 144.36%;
  font-weight: 600;
  font-family: Montserrat;
  display: inline-block;
  width: 762px;
  text-align: center;
}

.container-fluid {
  background-color: white;
}

.my-properties {
  padding: 20px;
}

.control--checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.frame-icon {
  width: 28px;
  position: relative;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
}

.note-register-one {
  position: relative;
  line-height: 144.36%;
}

.frame-parent1 {
  position: absolute;
  top: 150px;
  left: calc(50% - 217px);
  border-radius: 10px;
  background-color: #e3f2fd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 60px;
  gap: 6px;
  font-size: 16px;
}

.check-box-icon {
  position: absolute;
  top: 848px;
  left: 484px;
  width: 20px;
  height: 20px;
}

.drop-offpick-up-and-parking {
  text-decoration: underline;
  color: #3f8ed7;
}

.agree-to-terms-container {
  position: absolute;
  top: 950px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  line-height: 144.36%;
  color: #000;
  display: flex;
  align-items: center;
}

.continue-wrapper {
  position: absolute;
  top: 1000px;
  left: calc(50% - 102px);
  border-radius: 5px;
  background-color: #3f8ed7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px 60px;
  cursor: pointer;
  color: #fff;
}

.mark-pick-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 144.36%;
  text-align: center;
}

.designate-your-entrances {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  line-height: 144.36%;
  color: #767676;
  display: inline-block;
  width: 80%;
  text-align: center;
}

.designate-your-entrances .sub {
  top: 40% !important;
}

.capa-1-icon {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 166px;
  height: 166px;
  overflow: hidden;
}

.mark-pick-up-and-drop-off-parent,
.large-card,
.small-card {
  position: relative;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  background-color: #fff;
  height: 400px;
  overflow: hidden;
  flex-shrink: 0;
}

.large-card {
  width: 600px;
  height: 820px;
}

.small-card {
  width: 500px;
  height: 400px;
  margin-bottom: 20px;
}

.instance-parent {
  position: absolute;
  top: 220px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 40px;
}

.small-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 800px;
}

/* .my-properties {
	width: 100%;
	position: relative;
	background-color: #f5f5f5;
	height: 1080px;
	overflow: hidden;
	text-align: center;
	font-size: 20px;
	color: #333;
	font-family: Lato;
} */
.map-child maplibregl-map atlas-map {
  width: 785px !important;
  height: 1045px !important;
}

.maplibregl-canvas atlas-map-canvas {
  width: 785px !important;
  height: 1076px !important;
}

.vector-parents {
  align-self: stretch;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 20px;
  box-sizing: border-box;
  gap: 16px;
  width: 428px;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #333;
  font-family: Lato;
  height: 92vh;
  left: 100%;
}

.selected-option {
  padding: 8px;
}

.options-container {
  background-color: #f1f1f1;
  max-height: 200px;
  /* Adjust the max-height as needed */
  overflow-y: auto;
  border-top: none;
  display: none;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.custom-dropdown:hover .options-container {
  display: block;
}

/* .dropdown-option {
	background-color: #f1f1f1 !important;
	padding: 8px;
	border-bottom: 1px solid #ccc;
  } */

.dropdown-option:hover {
  background-color: #f1f1f1 !important;
}

.dropdown-option-label {
  display: block;
  font-weight: bold;
}

.dropdown-option-description {
  display: block;
  font-size: 0.9em;
}

.dropdown-container {
  top: -10px;
  position: relative;
  right: -200px;
}

.MuiInputBase-root-MuiOutlinedInput-root {
  height: 55px;
  background-color: white !important;
  border-radius: 10px;
}

.MuiFormControl-root {
  height: 55px;
  background-color: white !important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 1.4375em !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  height: 9px !important;
}

.MuiList-root-MuiMenu-list {
  padding: revert;
}

.mypropertymain {
  top: 139px;
  /* width: 605px; */
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  height: 485px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-family: Lato;
}

.logout-button {
  position: absolute;
  top: 32px;
  right: 100px;
  cursor: pointer;
  color: #000;
}

.AccordName1 {
  transition: background-color 0.3s ease;
}

.AccordName1:hover {
  background-color: #e5f3ff;
  cursor: pointer;
}

.AccordName1.selected {
  background-color: #e5f3ff;
}

.popup {
  width: 200px;
  z-index: 1000;
  padding: 15px 20px;
}

.popup-content {
  justify-content: flex-start;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.custom-paper-class-delete {
  border-radius: 5px !important;
  width: 500px !important;
  padding: 1rem !important;
  text-align: start !important;
}

.custom-button-cancel {
  text-transform: lowercase !important;
  background-color: #ccc !important;
  /* Ash color */
  color: black !important;
  padding: 8px 8px !important;
  width: 140px !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.custom-button-cancel:hover {
  background-color: #ccc !important;
}

.custom-button-delete {
  text-transform: lowercase !important;
  background-color: #3f8ed7 !important;
  color: white !important;
  padding: 8px 8px !important;
  width: 140px !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.custom-button-delete:hover {
  background-color: #3f8ed7 !important;
}

.AccordName1 {
  display: flex !important;
  padding: 7px 16px 5px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0 !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(201, 199, 199, 0.63);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.control--checkbox1 {
  position: relative;
  display: inline-block;
}

.control--checkbox1 input[type="checkbox"] {
  display: none;
}

.control__indicator1 {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  transition: background-color 0.3s;
}

.control--checkbox1 input[type="checkbox"]:checked+.control__indicator1 {
  background-color: #3f8ed7 !important;
  border-color: #3f8ed7 !important;
}

.control__indicator1::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s;
}

.control--checkbox1 input[type="checkbox"]:checked+.control__indicator1::after {
  opacity: 1;
}

.atlas-map .azure-maps-control-container {
  margin: 70px 10px !important;
  border-collapse: collapse !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  pointer-events: auto !important;
  position: relative !important;
}

.ParkingSpots {
  width: 605px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  height: 645px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-family: Lato;
}

.Markspots-Content {
  position: absolute;
  top: calc(35% + 65.5px);
  left: 30px;
  line-height: 24px;
  display: inline-block;
  width: 545px;
  text-align: center;
  white-space: pre-line;
}

.close-Markspots {
  position: absolute;
  top: 573px;
  left: calc(50% - 81.5px);
  border-radius: 5px;
  background-color: #e6e6e6;
  width: 163px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 8px;
  box-sizing: border-box;
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-top: 15px;
  padding-left: 8px;
}

.MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.MuiFilledInput-root {
  background-color: white !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #e2e3e5;
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 4px;
}



.PhoneInput:focus-within {
  border-color: #9ec5fe;
  box-shadow: 0 0 0 4px #cfe2ff;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  border: none;
  outline: none;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.password-requirements ul {
  list-style-type: none;
  padding-left: 0;
}

.password-requirements li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.password-requirements1 li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: green;
}

.password-requirements1 li::before {
  content: '✓';
  color: green;
  margin-right: 10px;
}

.password-requirements li::before {
  content: '-';
  color: black;
  margin-right: 10px;
  font-weight: 600;
  font-size: 20px;
}

.Addrees-search-results {
  top: 46%;
  position: absolute;
  width: 20%;
}

.icon-btn {
  width: 50px;
  height: 50px;
  background: white;
  color: #000;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.icon-btn-finish {
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  background: #6acd54;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
}


.icon-back {
  right: 230px;
  position: absolute;
  top: 800px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 20px;
  padding: 9px 20px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 19.2px;
  color: #333;
  flex-shrink: 1;
}

.registration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 2rem 2rem 0rem;
  background-color: #f8f9fa;
  height: 100vh;
}

.image-section {
  flex: 1;
}

.form-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.form-section {
  flex: 1;
  max-width: 700px;
  padding: 3rem 4.5rem 3rem 4.5rem;
}

h1 {
  font-size: 24px;
  margin-bottom: 0.5rem;
}

.register-button {
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: white;
  background-color: #0A66C2;
}

.register-button:hover {
  background-color: #0A66C2;
}

.otpbtn {
  width: 30%;
}

.goBackRegister {
  margin-right: 5%;
  width: 30%;
  background-color: white;
  color: #000;
}

.goBackRegister:hover {
  background-color: lightgrey;
}

.login-link {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}

.login-link a {
  color: #0A66C2;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.otp-inputs {
  border-radius: 5px;
  height: 60px;
  width: 55px;
  margin: 18px 55px 18px 0px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid gray;
  text-align: center;
}

.otpDescription {
  color: #9E9E9E;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0px;
}

.otp-resend {
  color: #333;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 50px 0px;
}

.internalempdescription {
  font-size: 14px;
  color: #666;
  margin-bottom: 3rem;
}

input,
select {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

input:focus,
select:focus {
  border-color: #0A66C2;
  ;
  outline: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checkbox-container input {
  margin-right: 0.5rem;
}



.error {
  color: red;
  font-size: 12px;
  margin-top: 6px;
  display: block;
}

.validation-error {
  margin-bottom: 15px;
  margin-top: -10px;
  text-align: end;
}

.checkbox {
  width: 4%;
  margin: 0px 15px 0px 0px;
}

.checkboxform {
  margin: 75px 0px 40px 0px !important;
  font-weight: 600;
}

.TermsCondition {
  font-size: medium;
}

.checkboxform input[type="checkbox"] {
  appearance: none;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 8px 9px 8px 9px;
}

.checkboxform input[type="checkbox"]:checked {
  border-color: #0A66C2;
  background-color: #0A66C2;
}

.checkboxform input[type="checkbox"]:checked::before {
  content: '✔';
  position: absolute;
  left: 2px;
  top: -2px;
  font-size: 14px;
  color: white;
}

.add-property-container {
  display: flex;
  justify-content: flex-start;
  margin-top: -40px;
}

.add-property-btn {
  background-color: #0A66C2;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2 !important;
}




.OnerRegistration-Container {
  max-width: 70%;
  margin: 3% auto;
  padding: 50px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  display: block;
}

.form-field {
  margin-right: 15px;
  text-align: left;

}

h4 {
  text-align: left;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

h5 {
  text-align: left;
  margin-bottom: 18px;
  color: #0A66C2;
}

.submit-button {
  width: 20%;
  padding: 10px;
  background-color: #0A66C2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0A66C2;
}

.GeoparkingImage {
  width: 100%;
  height: 100vh
}

.geoparkingloginheader {
  font-weight: 700
}

.dashboardContainer-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #f5f5f5;
}

.DashBoardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboardheaders {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.we-re-thrilled-to {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.registerframe {
  display: flex;
  justify-content: center;
}

.reg-property {
  padding: 18px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.illustration-auntum {
  margin-bottom: 20px;
}

.clip-path-group {
  max-width: 100%;
  height: auto;
}

.toggle-password-visibility {
  position: absolute !important;
  right: 10px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: pointer !important
}

.blue-link {
  color: blue;
  text-decoration: underline;
}

.css-1q2tlgs-MuiTypography-root {
  font-weight: 590 !important;
  font-size: 20px !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  color: black !important;
}

.lotmanagements {
  margin: 32px 37px;
}

.Parking-Only {
  align-content: center;
  padding: 2px 15px;
  background-color: white;
  height: 53px;
  border-radius: 3px;
  margin: 11px 3px 3px 21px;
  cursor: pointer;
}

.SearchFiltertab {
  margin: 2rem 1rem 1rem;
}

.GlobalSearch-Table {
  margin: 2rem 3rem;
}

.custom-data-grid {
  width: 95%;
}

.custom-data-grid .MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked {
  background-color: black !important;
  color: rgb(242, 237, 237) !important;
}

.MuiDataGrid-root .MuiDataGrid-container--top [role=row],
.MuiDataGrid-root .MuiDataGrid-container--bottom [role=row] {
  background-color: black !important;
  color: white !important;
}

.custom-data-grid .MuiCheckbox-root.Mui-checked,
.custom-data-grid .MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #1976d2 !important;
}

.custom-data-grid .MuiCheckbox-root.MuiCheckbox-indeterminate {
  background-color: white !important;
}

.MuiButtonBase-root-MuiCheckbox-root {
  color: grey !important;
}

.css-1uxd2ic {
  padding: 5px 16px !important;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2) !important;
  max-width: 1000px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon {
  color: white !important;
  font-size: inherit !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-filterIcon {
  color: white !important;
  font-size: inherit !important;
}

.gridtable {
  height: 650px !important;
  width: '100%' !important;
  margin: 0px 0px 0px 135px !important;
}

.css-9i2qh3 {
  padding: 8px !important;
}

.css-9i2qh3 {
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 0px 0px 0px !important;
}

.password-container input[type="password"]::-ms-reveal,
.password-container input[type="password"]::-ms-clear {
  display: none;
}

.password-container input[type="password"] {
  padding-right: 40px;
}

.marker {
  position: absolute;
  top: -65px;
  left: 26.5px;
  padding: 15px;
  background: white;
  border-radius: 10px;
  width: 230px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 100;
}

.ristrictedmarker {
  width: 200px;
  top: -48px;
  left: 25.5px;
}

.azure-maps-control-container {
  position: absolute !important;
  bottom: 80px !important;
  right: 57px !important;
  z-index: 1000 !important;
}

.Toastify__toast-container {
  margin-top: 70px !important;

}

.custom-toast {
  margin-top: 70px !important;
  z-index: 10 !important;
}

.scrollable-container {
  max-height: 500px;
  overflow-y: auto;
}

.highlightedText {
  color: red !important;
  font-size: 20px;
}

.restricted-badge {
  background-color: red;
  color: white;
  border-radius: 7px;
  padding: 4px 9px;
  font-size: 1.1rem;
  font-weight: bold;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 100;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .restricted-badge {
    font-size: 0.9rem;
    padding: 3px 8px;
  }
}

@media (max-width: 480px) {
  .restricted-badge {
    font-size: 0.8rem;
    padding: 2px 6px;
  }
}